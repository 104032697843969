import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import NavbarNoHamburgerNoButtons from '../../containers/Crypto/NavbarNoHamburgerNoButtons';
import BannerSectionNoWords from '../../containers/Crypto/BannerSectionNoWords';
import SEO from '../../components/seo';
import SideNavBar from '../../containers/Crypto/SideNavBar/Sidebar';
import store from '../../store';
import Footer from '../../containers/Crypto/Footer';

import SixthPageHELOCPasswordComponentRedux from '../../containers/Crypto/helocComponents/sixthPageHELOCPasswordComponent';

const sixthPageHELOCPassword = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Provider store={store}>
        <Fragment>
          <SEO title="Best Finance | Simple, Online, Fast" />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <div className="flex">
              {/*  <SideNavBar /> */}
              <div className="w-100">
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <NavbarNoHamburgerNoButtons />
                  </DrawerProvider>
                </Sticky>
                <BannerSectionNoWords />

                <p></p>
                <SixthPageHELOCPasswordComponentRedux />
                <p></p>
                <Footer />
              </div>
            </div>
          </ContentWrapper>
        </Fragment>
      </Provider>
    </ThemeProvider>
  );
};

export default sixthPageHELOCPassword;
