import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import { navigate } from 'gatsby';
import 'antd/dist/antd.css';
import './index.css';
import { fbq } from 'gatsby-plugin-facebook-pixel';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import ContactFromWrapper, { SectionMainWrapper } from './contact.style';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import GoogleaAddressSearch from '../../GoogleAutoComplete';
import {
  createUsers,
  getUser,
  showLoader,
  hideLoader,
  updateUserInfo
} from '../../../../actions';
import PdfTermsOfService from '../../../../documents/termsOfService.pdf';
import PdfElectronicCommunicationPolicy from '../../../../documents/electronicCommunicationsPolicy.pdf';
import PdfPrivacyPolicy from '../../../../documents/privacyPolicy.pdf';


const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const SixthPageHELOCPasswordComponent = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  title2,
  description,
  description2,
  description3,
  createUsers,
  showLoader,
  hideLoader,
  userToken,
  updateUserInfo,
  loading,
  currentUser,
  user,
}) => {
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onFinish = async (values) => {

	console.log("form submitted", formSubmitted)
	setFormSubmitted(true);
	console.log("form submitted", formSubmitted)

	var updateObject = {
		borrower: {
		  firstName: JSON.parse(localStorage.getItem('firstName')),
		  lastName: JSON.parse(localStorage.getItem('lastName')),
		  suffix: JSON.parse(localStorage.getItem('suffix')),
		  dateOfBirth: JSON.parse(localStorage.getItem('dateOfBirth')),
		  addressLineText: JSON.parse(
			localStorage.getItem('primaryResidencePropertyAddress')
		  ),
		  cityName: JSON.parse(localStorage.getItem('primaryResidenceCityName')),
		  stateCode: JSON.parse(
			localStorage.getItem('primaryResidenceStateCode')
		  ),
		  postalCode: JSON.parse(
			localStorage.getItem('primaryResidencePostalCode')
		  ),
		  countryCode: JSON.parse(localStorage.getItem('borrowerCountryCode')),
		  contactPointTelephoneValue: JSON.parse(localStorage.getItem('contactPointTelephoneValue')),
		  income: {
			totalYearlyIncome: JSON.parse(localStorage.getItem('annualIncome')),
			totalOtherIncome: JSON.parse(localStorage.getItem('otherIncome')),
		  },
		},
		subjectProperty: {
		  address: {
			addressLineText: JSON.parse(
			  localStorage.getItem('subjectPropertyAddress')
			),
			/* addressUnitIdentifier: JSON.parse(
			  localStorage.getItem('addressUnitIdentifier')
			), */
			cityName: JSON.parse(localStorage.getItem('subjectPropertyCity')),
			stateCode: JSON.parse(localStorage.getItem('subjectPropertyState')),
			postalCode: JSON.parse(
			  localStorage.getItem('subjectPropertyZipCode')
			),
		  },
		  propertyDetail: {
			propertyUsageType: JSON.parse(
			  localStorage.getItem('subjectPropertyUsageType')
			),
		  },
		},
		miscellaneous: {
		  financingPurpose: JSON.parse(localStorage.getItem('financingPurpose')),
		  //financingUse:
		},
		email: JSON.parse(localStorage.getItem('email')),
		password: values.password,
	};
    
    //call faecboook pixel upon clicking submit heloc

    if (typeof fbq !== 'undefined') {
      fbq('track', 'ApplicationSubmitted');
    }

	/* Object.assign(currentUser, {
		password: values.password,
	})	 */
	
	console.log("currentuser after password", currentUser)
	console.log("userToken", userToken)
	showLoader();
    await createUsers(updateObject)
	hideLoader();
	navigate('https://heloc.remnwholesale.com/login')
    //console.log('currentUser application direct from response', helocLink);
    console.log('currentUser application from redux', currentUser);

    // hideLoader();
    // navigate(helocLink.user.borrower.helocLinkURL);
    //  navigate(currentUser.borrower.helocLinkURL);
  };

  return (
    <SectionMainWrapper>
       {loading ? (
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}>
              <Box {...row}>
                <FullPageLoader />
              </Box>
            </Box>
          </Container>
        </Box>
      ) : formSubmitted ? (
        ''
      ) : ( 
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}></Box>
            <Box {...row}>
              {/*  <Box {...contactForm}> */}

              <ContactFromWrapper>
                <Form
                  //{...formItemLayout}
                  form={form}
                  layout="vertical"
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                  style={{ width: '80%', fontWeight: '600' }}
                >
					{!formSubmitted && (
                  <FeatureBlock
                    title={<Heading {...title} />}
                    description={<Text {...description} />}
                  />
				  )}
				  {/* {formSubmitted && (
                  <FeatureBlock
                    description={<Text {...description2} />}
                  />
				  )}
				  	{formSubmitted && (
				  <FeatureBlock
                    title={<Heading {...title2} />}
                  />
				  )} */}

				{/* upppercase, symbol, number 12 characters total */}
				{!formSubmitted && (
                <Form.Item
                    name="password"
                    label="Create password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password',
                      },
                      { 
                        min: 12,
                        message: 'Must be at least 12 characters',
                      },
					  {  //one lowercase letter, uppercaes letter, number and special character
						required: true,
						pattern: new RegExp('^(?=.*[a-z])'),
						message: "Lower case letter"
					  },
					  {  //one lowercase letter, uppercase letter, number and special character
						required: true,
						pattern: new RegExp('(?=.*[A-Z])(?=.*\\d)'),
						message: "Please add an uppercase."
					  },
					  {  //one lowercase letter, uppercaes letter, number and special character
						required: true,
						pattern: new RegExp('(?=.*\\d)'),
						message: "Please add a number."
					  },
					  {  //one lowercase letter, uppercaes letter, number and special character
						required: true,
						pattern: new RegExp('(?=.*[ -\/:-@\[-\`{-~])'),
						message: "Please add a symbol."
					  },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item> 
					 )}

					{!formSubmitted && (
					<Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      {
                        min: 9,
                        message: 'Must be at least 9 characters',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            'The two passwords that you entered do not match'
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
					)}

				{!formSubmitted && (
				<Form.Item
                  name="agreement"
                  valuePropName="checked"
                  //onChange={handleAcceptedTerms}
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject('Required'),
                    },
                  ]}
                >
                  <Checkbox>
                    I agree to the
                    <a href={PdfTermsOfService} target="_blank">
                      {' '}
                      <u>Terms of Use</u>{''}
                    </a>
                	,
                    <a href={PdfPrivacyPolicy} target="_blank">
					{' '}<u>Privacy Policy</u>
                    </a>
                    , and
                    <a href={PdfElectronicCommunicationPolicy} target="_blank">
                      {' '}
                      <u>Consent to Receive Electronic Loan Documents</u>{' '}
                    </a>
                  </Checkbox>
                </Form.Item>
				 )}

						{!formSubmitted && (
                  <Form.Item /* {...tailFormItemLayout} */>
                    <Button
                      type="primary"
                      htmlType="submit"
                      //color="#131176"
                      //onClick={blank()}
                      {...button}
                    >
                      See your offer
                    </Button>
                  </Form.Item>
						)}

                </Form>
              </ContactFromWrapper>
              {/* </Box> */}
            </Box>
          </Container>
        </Box>
       )}
    </SectionMainWrapper>
  );
};

SixthPageHELOCPasswordComponent.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

SixthPageHELOCPasswordComponent.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    //pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['20px', '20px', '20px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    fontColor: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
    marginRight: '8px',
    text: {
      fontSize: 16,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      color: 'white',
    },
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Login info',
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    lineHeight: ['40px', '40px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mt: '10px',
    mb: '20px',
    textAlign: ['left', 'left'],
  },
  title2: {
    content: 'Thanks!  Your free appraisal has been started.  Once the appraisal has been completed, you will be sent an email and a message with your offer!',
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    lineHeight: ['40px', '40px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mt: '10px',
    mb: '20px',
    textAlign: ['left', 'left'],
  },
  description: {
    content:
      "You'll need to be able to see your offer!",
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['left', 'left'],
  },
 /*  description2: {
    content:
      "Best Finance has a technology partnership with REMN.  We are able to provide our 15 minute HELOC ",
    fontSize: '10px',
    fontWeight: '400',
    color: '#000000',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['left', 'left'],
	wordWrap: 'break-word' // Add this property
  }, */
  description3: {
    content:
      "By pressing the “Check My Rate” button immediately following this notice, I am providing ‘written instructions’ to Best Finance, and Best Finance's technology platform and lending partner, REMN under the Fair Credit Reporting Act authorizing Best Finance and REMN to obtain information from my personal credit profile or other information from Experian. I authorize Best Finance and REMN to obtain such information solely to conduct a pre-qualification for credit and acknowledge this will not affect my credit score.",
    fontSize: '10px',
    fontWeight: '400',
    color: '#000000',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
	wordWrap: 'break-word' // Add this property
  },
};

const mapStateToProps = ({ root: { currentUser, loading, showSidebar } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
  loading,
  showSidebar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUsers: (formVals) => dispatch(createUsers(formVals)),
	updateUserInfo: (currentUser, userToken) => dispatch(updateUserInfo(currentUser, userToken)),
    getUser: (userId) => dispatch(getUser(userId)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    //toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

const SixthPageHELOCPasswordComponentRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SixthPageHELOCPasswordComponent);

export default SixthPageHELOCPasswordComponentRedux;
